import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faStar as fullStar} from '@fortawesome/pro-solid-svg-icons/faStar'
import {faStarHalfAlt} from '@fortawesome/pro-solid-svg-icons/faStarHalfAlt'
import {faStar as emptyStar} from '@fortawesome/pro-regular-svg-icons/faStar'

@Component({
  selector: 'cft-rating',
  templateUrl: './rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent {
  @Input()
  set rating(value: number | string) {
    this.ratingBackingField = isNaN(Number(value)) ? 0 : Number(value)
  }
  get rating(): number {
    return this.ratingBackingField
  }

  @Input()
  set total(value: number | string) {
    this.totalBackingField = isNaN(Number(value)) ? 5 : Math.round(Number(value))
  }
  get total(): number {
    return this.totalBackingField
  }

  @Input()
  separator = '/'

  @Input()
  hideTotal = false

  readonly fullStarIcon = fullStar
  readonly emptyStarIcon = emptyStar
  readonly halfStarIcon = faStarHalfAlt

  private ratingBackingField = 0
  private totalBackingField = 5

  get stars(): IconDefinition[] {
    return [...new Array(this.total)].map((_, index) => {
      const currentStar = index + 1

      return currentStar <= this.rating
        ? this.fullStarIcon
        : currentStar - this.rating < 1 && currentStar - this.rating > 0
          ? this.halfStarIcon
          : this.emptyStarIcon
    })
  }
}
