<ng-container>
  @for (star of stars; track star) {
    <fa-icon
      [icon]="star"
      class="mr-xs text-yellow-500"
      [attr.data-testid]="star === emptyStarIcon ? 'empty' : star === halfStarIcon ? 'half' : 'full'"
    ></fa-icon>
  }
  <span class="font-semibold">
    {{ rating | number: '0.1' }}
    @if (!hideTotal) {
      <span>
        {{ separator }}
        {{ total | number: '0.1' }}
      </span>
    }
  </span>
</ng-container>
